<template>
	<div>
		<div class="tabs-wrapper">
			<q-tabs
				indicator-color="primary"
				align="justify"
				active-color="primary"
				v-if="ready"
			>
				<q-route-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:to="{ name: tab.path_name }"
					:label="tab.label"
					:disable="tab.disable"
				>
					<slot v-bind='tab'>
						<q-tooltip v-if="on_call_settings_error_message && tab.disable">
							{{on_call_settings_error_message}}
						</q-tooltip>
					</slot>
				</q-route-tab>
			</q-tabs>
		</div>
		<div class="router-view-wrapper">
			<div v-if="ready">
				<router-view></router-view>
				<save-button v-if="!$route.path.includes('on_call')" @save="updateUser" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'
import helpers from '@/utils/helpers.js'

export default {
	name: 'Base',
	components: { SaveButton },
	data() {
		return {
			ready: false,
			tabs: [],
			is_on_call_settings: true,
			tabs_data: [],
			on_call_settings_error_message: ""
		}
	},
	computed: {
		...mapGetters(['logged_user', 'user_instance', 'cg_fields', 'user_field_values', 'select_data_fields']),
	},
	methods: {
		...mapActions([
			'getUser',
			'getUserOnCallSettings',
			'getCgFields',
			'updateDeviceFields',
			'updateSelectDataFields',
			'updateInputDataFields',
			'updateFieldValues',
			'updateUserData',
			'updateDataFieldErrorMessage',
			'clearDataFieldErrorMessage'
		]),
		generateTabsData(){
			this.tabs_data = [
				{
					path_name: 'User Devices',
					label: 'Devices',
				},
				{
					path_name: 'User Data',
					label: 'Data',
				},
				{
					path_name: 'User Settings',
					label: 'Settings',
				},
				{
					path_name: 'User OnCall',
					label: 'On Call',
					disable: !this.is_on_call_settings
				},
			]
		},
		setTabs() {
			let permission = this.logged_user.customer_permission
			let root_cg_permissions =
				this.$store.getters.current_CG.root_customer_group_permissions
			let has_permission = ['ADMIN', 'DATA'].includes(permission) ||
				(
					JSON.parse(this.$store.getters.current_CG.customer_group.settings).send_verification_email &&
					this.logged_user.id == this.$route.params.id
				)
			this.tabs = this.tabs_data.filter((tab) => {
				let include = null
				switch (tab.label) {
					case 'Devices':
						include = has_permission
						break
					case 'Data':
						include = has_permission
						break
					case 'Settings':
						include = has_permission
						break
					case 'On Call':
						include =
							['ADMIN', 'CONTACT', 'DATA'].includes(permission) &&
							root_cg_permissions.includes('On Call core platform access')
						break
				}
				return include
			})
		},
		async updateUser(){
			this.clearDataFieldErrorMessage()
			let user_instance = JSON.parse(JSON.stringify(this.user_instance))
			let user_field_values = JSON.parse(JSON.stringify(this.user_field_values))
			user_instance['values'] = user_field_values.map(
				el => {
					return {'field': el.field, 'values': el.option_label ? el.option_label : el.values}
				}
			)
			let response = await this.updateUserData({'user_id': this.user_instance.id, 'user_data': user_instance})
			if (response.error) {
				if (response.error_message.field) {
					this.updateDataFieldErrorMessage(response.error_message)
				}
				else if (response.error_message.email) {
					this.updateDataFieldErrorMessage({'field': 'email', error_message: response.error_message.email[0]})
				}
				else {
					let error_message = ""
					let keys = response.error_message instanceof Object ? Object.keys(response.error_message) : null
					if (keys){
						if (response.error_message[keys[0]] ==
							'Date has wrong format. Use one of these formats instead: YYYY-MM-DD.'
						){
							error_message = 'Date has wrong format. Use DD/MM/YYYY format instead.'
						}else{ error_message = response.error_message[keys[0]] }
					} else {
						error_message = response.error_message
					}
					this.$q.notify({
						timeout: 6700,
						message: error_message,
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})

				}

			} else {
				this.$q.notify('The user was updated successfully.')
			}
		}
	},
	async created() {
		this.$q.loading.show({
			delay: 400
		})
		let user_id = this.$route.params.id
		if (this.logged_user.id == user_id) {
			user_id = 'self'
		}
		let response = await this.getUser(user_id)
		if (response && response.error) {
			this.$q.notify({
				timeout: 6700,
				message: response.error_message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
		if (this.$store.getters.current_CG.root_customer_group_permissions.includes('On Call core platform access')){
			response = await this.getUserOnCallSettings(this.$route.params.id)
			if (response.error){
				this.on_call_settings_error_message = response.error_message
				this.is_on_call_settings = false
			}
		}

		await helpers.setFields(this.user_instance.customer_group)

		let field_values = JSON.parse(JSON.stringify(this.user_field_values))

		for (let field of this.select_data_fields){
			for (let value of field_values){
				if (value.field == field.id){
					if (field.field_type == 'SINGLESELECT'){
						try{
							value.option_label = field.options.filter(el => el.id.toString() == value.values)[0].value
						} catch {
							value.option_label = null
						}
					}
					break
				}
			}
		}
		this.updateFieldValues(field_values)
		this.generateTabsData()
		this.setTabs()
		this.ready = true;
		this.$q.loading.hide()
	},
}
</script>

<style lang="scss" scoped>
.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}
.tabs-wrapper {
	position: sticky;
	background: #fff;
	border: 1px solid $grey-3;
	z-index: 5;
}
</style>

<style lang="scss">
.settings-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	padding: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	background: $grey-1;
	border: 1px solid $grey-4;
}
.setting-title {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2rem;
	letter-spacing: normal;
	margin-top: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	text-align: left;
	border-bottom: 1px solid #e0e0e0;
	cursor: default;
}
.field-fab-wrapper {
	position: sticky;
	bottom: 20px;
	z-index: 5;
	width: fit-content;
	margin: 0 auto;
}
</style>
